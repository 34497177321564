// 主页
export const PageDashboard = '/dashboard';

// 账号
export const PageAccount = '/account';

// 金额调整
export const PageAdjustment = '/adjustment';
export const PageDiscount = '/adjustment/discount';
export const PageFee = '/adjustment/fee';
export const PageDiscountCategory = '/adjustment/discount_category';

// 设备
export const PageDevice = '/device';
export const PageDeviceAll = '/device/all';
export const PageDevicePrinters = '/device/printers';

// 平面图
export const PageFloorPlan = '/floor-plan';

// 菜单管理
export const PageMenuManagement = '/menu-management';
export const PageMenuManagementForAvailability = '/menu-management/availability';
export const PageMenuManagementForMenus = '/menu-management/menus';
export const PageMenuSetup = '/menu-management/menus-backup';
export const PageMenuManagementForCategories = '/menu-management/categories';
export const PageMenuManagementForCategoryEdit = `${PageMenuManagementForCategories}/edit/:id`;
export const PageMenuManagementForSalesCategory = '/menu-management/sales-category';
export const PageMenuManagementForPriceEditor = '/menu-management/dishes';
export const PageMenuManagementForDishPreference = '/menu-management/dish-preference';
export const PageMenuManagementForDishPreferenceEdit = '/menu-management/dish-preference/edit/:id';
export const PageMenuManagementForCustomizations = '/menu-management/customizations';
export const PageCustomizationList = '/menu-management/customizations/latest';
export const PageCustomizationListLegacy = '/menu-management/customizations/legacy';
export const PageMenuManagementForDishAdd = '/menu-management/dishes/add';
export const PageMenuManagementPrinterMapping = '/menu-management/printer-mapping';
export const PageMenuDecoration = '/menu-management/menu-decorate';
export const PageBatchUpdatePricing = '/menu-management/dishes/bulk-edit-price';
export const PageBatchUpdateMemberPricing = '/menu-management/dishes/bulk-edit-member-price';
export const PageBulkDiscountExempt = '/menu-management/dishes/bulk-discount-exempt';
export const PageBulkTaxExempt = '/menu-management/dishes/bulk-tax-exempt';
export const PageMenuSettings = '/menu-management/dishes/settings';
export const PageMenuManagementForCustomizationEditLatest = '/menu-management/customizations/edit-latest/:id';
export const PageAddCustomizationLatest = '/menu-management/customizations/add-latest';

// 配方管理
export const PageMenuManagementFormula = '/menu-management/formula';
export const PageMenuManagementFormulaGuideline = `${PageMenuManagementFormula}/guideline`;
export const PageMenuManagementFormulaCustomizationsCombination = `${PageMenuManagementFormula}/customizations-combination`;
export const PageMenuManagementFormulaItemCodeAssociation = `${PageMenuManagementFormula}/item-code-association`;
export const PageOrders = '/orders';
export const PageClosedOrders = `${PageOrders}/closed`;
export const PageOpenOrders = `${PageOrders}/open`;

// 报表
export const PageReport = '/reports';
export const PageSalesReport = '/reports/sales';
export const PagePayoutReport = '/reports/payout';
export const PagePayoutFeeReport = '/reports/payout-fee';
export const PageCashReport = '/reports/cash-statement';
export const PageInternalMonthlyStatement = '/reports/monthly-internal';
export const PageMonthlyStatement = '/reports/monthly';
export const PageGiftCard = '/reports/gift-card';

// 团队管理
export const PageTeam = '/team-management';
export const PageTeamRolesAndMembers = '/team-management/roles_and_members';
export const PageTeamMembers = '/team-management/roles_and_members/members';
export const PageTeamRoles = '/team-management/roles_and_members/roles';
export const PageTeamDepartment = '/team-management/roles_and_members/departments';
export const PageTeamBreaks = '/team-management/breaks';
export const PageWorkforce = '/team-management/workforce';
export const PageWorkforceProductivity = '/team-management/workforce/productivity';
export const PageWorkforceTimeLog = '/team-management/workforce/time-log';
export const PageWorkforceLaborSummary = '/team-management/workforce/labor-summary';
export const PageWorkforceTipSummary = '/team-management/workforce/tips';
export const PageUserActivities = '/team-management/workforce/user-activities';
export const PageRoleAdd = '/team-management/role/add';
export const PageRoleEdit = '/team-management/role/edit/:id';
export const PageDepartmentAdd = '/team-management/department/add';
export const PageDepartmentEdit = '/team-management/department/edit/:id';
export const PageTipsAllocation = '/team-management/tips-allocation';
export const PageTipsAllocationSettings = '/team-management/tips-allocation/settings';
export const PageTipsPolicyAdd = '/team-management/tips-allocation/add';
export const PageTipsPolicyEdit = '/team-management/tips-allocation/edit/:id';
export const PageEmployeeClockout = '/team-management/employee-clockout';

// 设置
export const PageSettings = '/settings';
export const PageWorkflowSettings = '/settings/workflow';
export const PageWorkflowShoppingCartSettings = '/settings/workflow/shopping-cart';
export const PageTipsSettings = '/settings/tips';
export const PagePrintoutsSettings = '/settings/printouts';
export const PageReportsSettings = '/settings/reports';
export const PageGlobalPreferenceSettings = '/settings/global';

// 交易
export const PageTransaction = '/transactions';
export const PageTransactionOthers = '/transactions/others';

// 智能点餐
export const PageSmartRestaurantSettings = '/smart-ordering-settings';
export const PageOnlineSettings = `${PageSmartRestaurantSettings}/online`;
export const PageQRCodeSettings = `${PageSmartRestaurantSettings}/qrcode-ordering`;
export const PageQRCodeDownload = `${PageQRCodeSettings}/download`;
export const PageKioskSettings = `${PageSmartRestaurantSettings}/kiosk-ordering`;
export const PageTabletSettings = `${PageSmartRestaurantSettings}/tablet-ordering`;
export const PageBuffetSettings = `${PageSmartRestaurantSettings}/buffet-ordering`;
export const PageOnlineOrderingSettings = `${PageSmartRestaurantSettings}/online-ordering`;
export const PageOnlineFee = `${PageOnlineOrderingSettings}/fee-and-tips`;
export const PageOnlineDisplay = `${PageOnlineOrderingSettings}/display`;
export const PageOnlineGeneral = `${PageOnlineOrderingSettings}/general`;
export const PageOnlineGeneralBlacklist = `${PageOnlineOrderingSettings}/blacklist`;

// 客显系统
export const PageCustomerDisplaySettings = '/customer-display-settings';
export const PageCustomerPickupScreenSettings = `${PageCustomerDisplaySettings}/customer-pickup-screen`;
export const PageCFDSettings = `${PageCustomerDisplaySettings}/cfd`;

// 预约 & 排队
export const PageReservationAndWaitlistSettings = '/reservation-and-waitlist';
export const PageWaitListSettings = `${PageReservationAndWaitlistSettings}/waitlist`;
export const PageReservationSettings = `${PageReservationAndWaitlistSettings}/reservation`;

// 会员
export const PageLoyalty = '/loyalty';
export const PageLoyaltyOverview = `${PageLoyalty}/overview`;
export const PageLoyaltyMembers = `${PageLoyalty}/members`;
export const PageLoyaltyTranscations = `${PageLoyalty}/transactions`;
export const PageLoyaltySettings = `${PageLoyalty}/settings`;
export const PageLoyaltyAdvertisingAndBenefits = `${PageLoyalty}/advertising-benefits`;
export const PageLoyaltyAdvertisingAndBenefitsAdvertising = `${PageLoyaltyAdvertisingAndBenefits}/advertising`;
export const PageLoyaltyAdvertisingAndBenefitsBenefits = `${PageLoyaltyAdvertisingAndBenefits}/benefits`;
export const PageLoyaltyPointsSettings = `${PageLoyalty}/points-settings`;
export const PageLoyaltyPointsReport = `${PageLoyalty}/points-report`;
export const PageLoyaltyCampaign = `${PageLoyalty}/campaign`;
export const PageLoyaltyAutopilot = `${PageLoyaltyCampaign}/autopilot`;
export const PageLoyaltyAutopilotEdit = `${PageLoyaltyAutopilot}/edit`;
export const PageLoyaltyManualCampaign = `${PageLoyaltyCampaign}/manual-campaign`;
export const PageLoyaltyManualCampaignAdd = `${PageLoyaltyManualCampaign}/add`;
export const PageLoyaltyAccount = `${PageLoyaltyCampaign}/account`;
export const PageLoyaltyManualCampaignEdit = `${PageLoyaltyManualCampaign}/edit`;

// 激活/登录/重设密码
export const PageRestaurantActivate = '/user/activate';
export const PageUserLogin = '/user/login';
export const PageUserResetPassword = '/user/reset-password';

// 促销管理
export const PagePromotions = '/promotion-management/promotions';
export const PagePromotionAdd = '/promotion-management/promotions/add';
export const PagePromotionEdit = '/promotion-management/promotions/edit/:id';
export const PagePromotionDuplicate = '/promotion-management/promotions/duplicate/:id';

//review 评价
export const PageReviews = '/reviews';
export const PageReviewsSetting = `${PageReviews}/settings`;
export const PageReviewsDetails = `${PageReviews}/details`;
export const PageReviewsReport = `${PageReviews}/reports`;

// kds
export const PageKitchenDisplayScreenSettings = '/kitchen-display-screen';
