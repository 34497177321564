import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { currentUserSelector } from 'src/selector/user';
import { extraVirtualRoleName } from 'src/utils/user';
import { IDENTITY_ADMIN } from 'src/consts/user';

const useWatermarkConfig = () => {
  const { identity, currentUser } = useSelector(currentUserSelector);

  const username = useMemo(() => {
    if (!currentUser) return null;
    const { family_name = '', given_name = '' } = currentUser;
    return [given_name, family_name].join(' ');
  }, [currentUser]);

  const roleName = useMemo(() => {
    if (!currentUser) return null;

    return extraVirtualRoleName(currentUser);
  }, [currentUser]);

  return {
    needWatermarker: identity === IDENTITY_ADMIN,
    username,
    roleName,
  };
};

export default useWatermarkConfig;
