import React, { useEffect, useRef } from 'react';
import { formatTimeInSpecificZone } from 'src/utils/utils';
import { generateWaterMarkerImage } from 'src/utils/watermarker';

// 容器样式，不能修改，如果修改则触发重绘
const fixedStyles = {
  position: 'relative',
  overflow: 'visible',
};

/**
 * 判断水印元素修改会删除
 * @param {MutationRecord} mutation 变化的元素
 * @param {HTMLDivElement} watermarkElement 水印元素
 * @return { modified: boolean, removed: boolean }
 */
function detectWatermarkMutation(mutation, watermarkElement) {
  let modified = false;
  let removed = false;

  // 判断是否删除
  if (mutation.removedNodes.length) {
    removed = Array.from(mutation.removedNodes).some((node) => node === watermarkElement);
  }

  // 判断是否修改水印元素的样式
  if (mutation.type === 'attributes' && mutation.target === watermarkElement) {
    modified = true;
  }

  return { modified, removed };
}

function convertCamelToHyphen(rawString) {
  return rawString.replace(/[A-Z]/g, (match) => '-' + match.toLowerCase());
}

/**
 * @param {Object} props
 * @param {String} props.username 用户名
 * @param {React.ReactNode} props.children 需要打水印的内容
 * @param {boolean} props.needWatermarker 是否需要加水印
 * @param {String} props.roleName 角色名
 * @param {import ('react').CSSProperties} props.mergeStyle 需要合并的样式，通常为 {minHeight: **}
 */
const AdminWaterMarker = ({ username, children, needWatermarker, roleName, mergeStyle = {} }) => {
  const containerRef = useRef(null);
  const watermarkElementRef = useRef(null);
  const styles = { ...fixedStyles, ...mergeStyle };

  useEffect(() => {
    if (!needWatermarker || !username || !containerRef.current) return;

    const container = containerRef.current;
    addWatermark(username, roleName);
    const observerOptions = {
      childList: true,
      attributes: true,
      subtree: true,
    };
    const mutationCallback = (mutationList) => {
      for (const mutation of mutationList) {
        const { modified, removed } = detectWatermarkMutation(mutation, watermarkElementRef.current);
        // 修改水印元素样式/删除水印元素
        if (modified || removed) {
          removeWatermark(removed);
          addWatermark(username, roleName);
        } else if (mutation.target === container && mutation.attributeName === 'style') {
          // 如果修改的是容器的样式
          const styleKeys = Object.keys(styles);
          for (const key of styleKeys) {
            const originalVal = styles[key];
            const convertedKey = convertCamelToHyphen(key);
            const currentVal = container.style.getPropertyValue(convertedKey);
            if (currentVal !== originalVal) {
              container.style.setProperty(convertedKey, originalVal);
            }
          }
        }
      }
    };
    const observer = new MutationObserver(mutationCallback);
    observer.observe(container, observerOptions);

    return () => {
      observer.disconnect();
    };
  }, [username, needWatermarker, roleName, styles]);

  function addWatermark(username, roleName) {
    if (!username || watermarkElementRef.current) return;

    const timestamp = formatTimeInSpecificZone(Date.now(), { format: 'M/D/YY hh:mm:ss A' });
    const watermarkImage = generateWaterMarkerImage(username, timestamp, roleName);
    const watermarkElement = document.createElement('div');
    watermarkElement.setAttribute(
      'style',
      `position:absolute;inset:0;z-index:1000;background-image:url(${watermarkImage});background-repeat:repeat;background-position:0 0;pointer-events:none;`
    );
    containerRef.current.appendChild(watermarkElement);
    watermarkElementRef.current = watermarkElement;
  }

  function removeWatermark(removed) {
    if (!watermarkElementRef.current) return;

    if (!removed) {
      try {
        containerRef.current.removeChild(watermarkElementRef.current);
      } catch (e) {
        console.log('element has been removed');
      }
    }

    watermarkElementRef.current = null;
  }

  if (!needWatermarker) return children;

  return (
    <div style={styles} ref={containerRef}>
      {children}
    </div>
  );
};

export default AdminWaterMarker;
