// reports
export const REPORTS = 'reports';
export const REPORTS_SALES = 'reports:sales';
export const REPORTS_PAYOUT = 'reports:payout';
export const REPORTS_MONTHLY = 'reports:monthly';
export const REPORTS_VOIDED_ITEMS = 'report:void:dishitem';
export const REPORTS_CASH = 'reports:cash';
export const REPORTS_GIFT_CARD = 'reports:giftcard';
export const REPORTS_PAYOUT_FEE = 'reports:payoutfee:report';
export const TEAM = 'sys:team';

// transaction
export const TRANSACTION = 'transaction';
export const TRANSACTION_REFUND = 'transaction:refunds:create';
export const TRANSACTION_NOT_OPEN_CASHBOX_ON_CASH_PAY = 'transaction:payment:keepcashself';
export const APPLY_PROMOTION = 'transaction:promotion:apply';
export const TRANSACTION_RECEIPT_RESEND = 'checkout:receipt:resend';

// break
export const BREAK_MANAGEMENT = 'sys:team:break';

// role
export const ROLES_AND_MEMBERS = 'sys:team:roles_and_members';
export const ROLE_LIST = 'sys:team:role';
export const ROLE_DETAIL = 'sys:team:role:list';
export const ROLE_CREATE = 'sys:team:role:create';
export const ROLE_EDIT = 'sys:team:role:update';
export const ROLE_DELETE = 'sys:team:role:delete';

// member
export const MEMBER_LIST = 'sys:team:member';
export const MEMBER_CREATE = 'sys:team:member:create';
export const MEMBER_EDIT = 'sys:team:member:update';
export const MEMBER_DELETE = 'sys:team:member:delete';
export const VIEW_PAY = 'sys:team:member:view_pay';
export const EDIT_PAY = 'sys:team:member:update_pay';

// department
export const DEPARTMENT_LIST = 'sys:team:department';
export const DEPARTMENT_CREATE = 'sys:team:department:create';
export const DEPARTMENT_EDIT = 'sys:team:department:edit';
export const DEPARTMENT_DELETE = 'sys:team:department:delete';

// tips
export const TIPS_MANAGEMENT = 'sys:team:tips';
export const EDIT_TIPS_ALLOCATION_SETTINGS = 'sys:team:tips:edit';
export const CREATE_TIPS_SHEET = 'sys:team:tips:create';
export const MANAGE_TIPS_SHEET = 'sys:team:tips:manage';

// device
export const DEVICE = 'sys:device';
export const DEVICE_EDIT = 'sys:device:update';
export const DEVICE_DELETE = 'sys:device:delete';
export const DEVICE_ACTIVE = 'sys:device:active';
export const DEVICE_DEACTIVE = 'sys:device:deactive';

// category
export const CATEGORY = 'sys:menu:categories';
export const CATEGORY_CREATE = 'sys:menu:category:create';
export const CATEGORY_EDIT = 'sys:menu:category:edit';
export const CATEGORY_DELETE = 'sys:menu:category:delete';
export const CATEGORY_SORT = 'sys:menu:category:sequence';

// sales category
export const SALES_CATEGORY = 'sys:menu:sales_category';
export const SALES_CATEGORY_CREATE = 'sys:menu:sales_category:create';
export const SALES_CATEGORY_EDIT = 'sys:menu:sales_category:edit';
export const SALES_CATEGORY_DELETE = 'sys:menu:sales_category:delete';

// menu management
export const MENU = 'sys:menu';
export const MENU_AVAILABILITY = 'sys:menu:availability';
export const MENU_PREFERENCES = 'sys:menu:preferences';
export const MENU_SETUP = 'sys:menu:menu';
export const MENU_CUSTOMIZATIONS = 'sys:menu:customization';
export const DISH_ITEM_CREATE = 'sys:menu:dish:create';
export const DISH_ITEM_EDIT = 'sys:menu:dish:update';
export const DISH_ITEM_DELETE = 'sys:menu:dish:delete';
export const DISH_ITEM_SORT = 'sys:menu:dishitem:sequence';
export const MENU_PRICE_EDITOR = 'sys:menu:price:edit';
export const MENU_DISH_PRICE_EDIT = 'sys:menu:dishprice:edit';
export const MENU_PRINTER_MAPPING = 'sys:device:printer';
export const EDIT_PRINTER_MAPPING = 'sys:device:printer:update';
export const MENU_FORMULA = 'sys:menu:formula';

// customization
export const OPTION_PRICE_EDIT = 'sys:menu:optionprice:edit';

// timecards
export const TIMECARDS = 'sys:timecards';
export const TIMECARDS_CREATE = 'sys:timecards:create';
export const TIMECARDS_EDIT = 'sys:timecards:update';
export const TIMECARDS_DELETE = 'sys:timecards:delete';

// order
export const ORDERS_AND_SHOPPING = 'sys:orders_and_shoppingcart';
export const ORDERS = 'sys:orders';
export const ORDER_DELETE = 'sys:orders:delete';
export const VIEW_ALL_ORDERS = 'sys:orders:view:all';
export const ORDERS_UNLOCK = 'orders:unlock';

// account
export const ACCOUNT = 'sys:account';

// smart ordering
export const SETTING_SMART_ORDERING = 'sys:settings:smart_ordering';
export const SETTING_QRCOD_ORDERING = 'sys:settings:qrcode';
export const SETTING_TABLET_ORDERING = 'sys:settings:tablet_ordering';
export const SETTING_KIOSK_ORDERING = 'sys:settings:kiosk_ordering';
export const SETTING_BUFFET_ORDERING = 'sys:settings:buffet_ordering';
export const SETTING_ONLINE_ORDERING = 'sys:settings:online_ordering';
export const SETTING_ONLINE = 'sys:settings:general';

// reservation & waitlist
export const SETTING_RESERVATION_AND_WAITLIST = 'sys:settings:reservation_and_waitlist';
export const SETTING_WAITLIST = 'sys:settings:waitlist';
export const SETTING_RESERVATION = 'sys:settings:reservation';

// customer display
export const SETTING_CUSTOMER_DISPLAY = 'sys:settings:customer_display';
export const SETTING_CUSTOMER_PICKUP_SCREEN = 'settings:customer_pickup_screen';
export const SETTING_CFD = 'sys:settings:cfd';

// setting
export const SETTING = 'sys:settings';
export const SETTING_TIPS = 'sys:settings:tips';
export const SETTING_PRINTOUTS = 'sys:settings:printouts';
export const SETTING_QRCODE_ORDERING = 'sys:settings:qrcode';
export const SETTING_SETTLE_TIME = 'sys:settings:settletime:update';
export const SETTING_CLOSEOUT_REPORT_CUTEOFF_TIME = 'sys:settings:closeoutreportcuteofftime:update';
export const SETTING_PREFERENCE_EDIT = 'sys:settings:preference:update';
export const SETTING_QRCODE_ONLINE_PAYMENT = 'sys:settings:qrcode:onlinepayment';
export const SETTING_SERVICE = 'sys:settings:service';
export const SETTING_REPORTS = 'sys:settings:sales_reports';
export const SETTING_GLOBAL = 'sys:settings:global';
export const SETTING_SMART_RESTAURANT = 'settings:smart_restaurant';

export const HOME = 'home';

// floor plan
export const FLOOR_PLAN = 'sys:floorplan';
export const FLOOR_PLAN_SECTION_CREATE = 'sys:floorplan:create';
export const FLOOR_PLAN_SECTION_EDIT = 'sys:floorplan:update';
export const FLOOR_PLAN_SECTION_DELETE = 'sys:floorplan:delete';
export const FLOOR_PLAN_SECTION_SORT = 'sys:floorplan:sort';

// table service
export const TABLE_SERVICE = 'sys:tableservice';
export const CHANGE_EMPLOYEE_FOR_ALL_ORDERS = 'sys:tableservice:changeemployee';
export const TRANSFER_OWN_ORDERS_TO_ANOTHER_EMPLOYEE = 'sys:tableservice:transferownorders';

/* adjustment start  */
export const ADJUSTMENT = 'sys:adjustment';

export const ADJUSTMENT_DISCOUNT_CATEGORY = 'sys:discount_category';

// fee
export const FEE = 'sys:fee';
export const FEE_CREATE = 'sys:fee:create';
export const FEE_EDIT = 'sys:fee:update';
export const FEE_DELETE = 'sys:fee:delete';
// discount
export const DISCOUNT = 'sys:discount';
export const DISCOUNT_CREATE = 'sys:discount:create';
export const DISCOUNT_EDIT = 'sys:discount:update';
export const DISCOUNT_DELETE = 'sys:discount:delete';
// alternative payment
export const ALTERNATIVE_PAYMENTS = 'sys:alternative_payments';
export const ALTERNATIVE_PAYMENTS_CREATE = 'sys:alternative_payments:create';
export const ALTERNATIVE_PAYMENTS_EDIT = 'sys:alternative_payments:edit';
export const ALTERNATIVE_PAYMENTS_DELETE = 'sys:alternative_payments:delete';
/* adjustment end  */

// checkout
export const CHECKOUT = 'checkout';
export const CHECKOUT_APPLY_DISCOUNT = 'checkout:discount:apply';
export const CHECKOUT_APPLY_FEE = 'checkout:fee:apply';

// layout
export const LOYALTY = 'loyalty';
export const LOYALTY_MEMBERS = 'loyalty:members';
export const LOYALTY_MEMBERS_CREATE = 'loyalty:members:create';
export const LOYALTY_MEMBERS_EDIT = 'loyalty:members:edit';
export const LOYALTY_SETTINGS = 'loaylty:settings';

//Advertising & Benefits
export const LOYALTY_ADVERTISINGANDBENEFITS = 'loyalty:advertisingAndBenefits';
export const LOYALTY_ADVERTISINGANDBENEFITS_ADVERTISING = 'loyalty:advertisingAndBenefits:advertising';
export const LOYALTY_ADVERTISINGANDBENEFITS_BENEFITS = 'loyalty:advertisingAndBenefits:benefits';

export const LOYALTY_TRANSACTIONS = 'loyalty:transaction';
export const LOYALTY_CAMPAIGN = 'loyalty:campaign';
export const LOYALTY_AUTOPOLIT = 'loyalty:autopolit';
export const LOYALTY_MANUAL_CAMPAIGN = 'loyalty:manualcampaign';
export const LOYALTY_ACCOUNT = 'loyalty:account';
export const LOYALTY_POINTS = 'loyalty:points';
export const LOYALTY_POINTS_REPORTS = 'loyalty:points:report';
// loyalty CRM
export const LOYALTY_MANUAL_CAMPAIGN_CREATE = 'loyalty:manualcampaign:create';
export const LOYALTY_MANUAL_CAMPAIGN_EDIT = 'loyalty:manualcampaign:edit';
export const LOYALTY_MANUAL_CAMPAIGN_DUPLICATE = 'loyalty:manualcampaign:duplicate';
export const LOYALTY_AUTOPILOT_CAMPAIGN_EDIT = 'loyalty:autopilotcampaign:edit';
export const LOYALTY_SETTINGS_SET_CUSTOM_RECHARGE = 'loaylty:settings:setCustomRecharge';

//giftcard
export const REPORTS_GIFT_CARD_DELETE = 'report:giftcard:delete';
export const REPORTS_GIFT_CARD_SUSPEND = 'report:giftcard:suspend';
export const REPORTS_GIFT_CARD_REACTIVE = 'report:giftcard:reactive';

export const LOYALTY_REPORTS = 'reports:loyalty';

// workforce
export const WORKFORCE = 'reports:workforce';
export const WORKFORCE_PRODUCTIVITY = 'reports:workforce:productivity';
export const WORKFORCE_TIME_LOG = 'reports:workforce:time_log';
export const WORKFORCE_LABOR_SUMMARY = 'reports:workforce:labor_summary';

//reservation

export const CREATE_RESERATION_TABLE_SETTING = 'sys:reservation:tableSetting:add';
export const EDIT_RESERATION_TABLE_SETTING = 'sys:reservation:tableSetting:edit';
export const DELETE_RESERATION_TABLE_SETTING = 'sys:reservation:tableSetting:delete';

export const CREATE_RESERATION_OPEN_TIME_REGULAR_SETTING = 'sys:reservation:opentimeRegular:add';
export const EDIT_RESERATION_OPEN_TIME_REGULAR_SETTING = 'sys:reservation:opentimeRegular:edit';
export const DELETE_RESERATION_OPEN_TIME_REGULAR_SETTING = 'sys:reservation:opentimeRegular:delete';

export const CREATE_RESERATION_OPEN_TIME_SPECIAL_SETTING = 'sys:reservation:opentimeSpecial:add';
export const EDIT_RESERATION_OPEN_TIME_SPECIAL_SETTING = 'sys:reservation:opentimeSpecial:edit';
export const DELETE_RESERATION_OPEN_TIME_SPECIAL_SETTING = 'sys:reservation:opentimeSpecial:delete';

export const CREATE_RESERATION_CLOSE_TIME_SETTING = 'sys:reservation:closetime:add';
export const EDIT_RESERATION_CLOSE_TIME_SETTING = 'sys:reservation:closetime:edit';
export const DELETE_RESERATION_CLOSE_TIMEL_SETTING = 'sys:reservation:closetime:delete';

// promotion
export const PROMOTIONS = 'promotions';
export const CREATE_OR_DUPLICATE_PROMPTION = 'promotions:add';
export const EDIT_OR_EXTEND_PROMOTION = 'promotions:edit';
export const PAUSE_PROMOTION = 'promotions:pause';
export const DELETE_PROMOTION = 'promotions:delete';

// shopping carts
export const SHOPPING_CART = 'shoppingcart';
export const MANUALLY_ENTER_WEIGHT = 'shoppingcart:electronic_scale_manually_enter_weight';

export const EMPLOYEE_CLOCKOUT = 'sys:team:employee_clockout';
// menu decoration
export const EDIT_MENU_DECORATION = 'sys:settings:qrcode:menu_decorate';
export const PUBLISH_MENU_DECORATION = 'sys:settings:qrcode:publish_menu_decoration';

//reivew 评价
export const ORDER_REVIEWS = 'reviews';
export const ORDER_REVIEWS_SETTINGS = 'reviews:settings';
export const ORDER_REVIEWS_DETAILS = 'reviews:details';
export const ORDER_REVIEWS_REPORTS = 'reviews:reports';
export const ORDER_REVIEWS_DETAILS_VIEW_ORDER_DETAIL = 'reviews:viewOrderDetail';

// kitchen display screen
export const SETTING_KITCHEN_DISPLAY_SCREEN = 'sys:settings:kitchen_display_screen';
