import { POST } from 'src/utils/request';
import { API_PREFIX, AUTH_API_PREFIX } from 'src/consts';
import { ADMIN_AUTH_CLIENT_ID, POS_AUTH_CLIENT_ID } from 'src/consts/third_party';
import { encrypt } from 'src/utils/encrypt';

export function getAuthorToken({ email, password }) {
  return POST(`${API_PREFIX}/v2/login`, { email, password, grant_type: 'password' }, { noNeedLogin: true });
}

export async function loginByEmailAndPassword({ email, password }) {
  const encryptedData = await encrypt({ password });
  return POST(
    `${AUTH_API_PREFIX}/oauth/token`,
    { email, client_id: POS_AUTH_CLIENT_ID, grant_type: 'password', ...encryptedData },
    { noNeedLogin: true }
  );
}

export function getOauthToken({ code, redirect_uri }) {
  return POST(
    `${AUTH_API_PREFIX}/oauth/token`,
    { code, redirect_uri, client_id: ADMIN_AUTH_CLIENT_ID, grant_type: 'authorization_code' },
    { noNeedLogin: true }
  );
}

export function getPusherAuthorToken(data) {
  return POST(`${API_PREFIX}/v1/pusher/auth`, data, { silence: true, noNeedLogin: true });
}
