class RequestManager {
  constructor() {
    this._requestTokenMapping = new Map();
    this.allCanceled = false;
  }

  resetCanceledStatus() {
    this.allCanceled = false;
  }

  addRequestCache(url, abortController) {
    this.allCanceled = false;
    this._requestTokenMapping.set(url, abortController);
  }

  removeRequestCache(url) {
    this._requestTokenMapping.delete(url);
  }

  cancelRequest(url) {
    const abortController = this._requestTokenMapping.get(url);
    if (abortController && abortController.abort) abortController.abort();
    this.removeRequestCache(url);
  }

  cancelRequestByUrlPattern(urlPattern) {
    for (const [url, abortController] of this._requestTokenMapping) {
      if (url.includes(urlPattern)) {
        try {
          abortController.abort();
        } catch (e) {
          console.log('cancel failed');
        }

        this.removeRequestCache(url);
      }
    }
  }

  cancelAll() {
    this.allCanceled = true;
    for (const abortController of this._requestTokenMapping.values()) {
      if (!abortController) continue;

      try {
        abortController.abort();
      } catch (e) {
        console.log('cancel failed');
      }
    }
    this._requestTokenMapping.clear();
  }
}

export default new RequestManager();
