import moment from 'moment';
import { FORMAT_WITH_HHMMSS, LOCALE_EN } from '../consts';

const FORMAT = 'MM/DD/YYYY hh:mm:ss a';
const langs = {
  en: 'en-gb',
  zh: 'zh-cn',
};

export const WEEK_NAMES = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
export function getDateRangeDesc(query, locale = LOCALE_EN) {
  const { start_at, end_at } = query;
  if (!start_at || !end_at) return null;

  const isEN = locale === LOCALE_EN;
  const start = moment(query.start_at, FORMAT_WITH_HHMMSS).format(FORMAT);
  const end = moment(query.end_at, FORMAT_WITH_HHMMSS).format(FORMAT);
  return `${isEN ? 'Date Range' : '日期区间'}: ${start} - ${end}`;
}

export function parseMinutes(minutes) {
  let day = Math.floor(minutes / (24 * 60));
  let hour = Math.floor((minutes - day * 24 * 60) / 60);
  let min = minutes - day * 24 * 60 - hour * 60;

  return {
    day,
    hour,
    min,
  };
}

export async function updateStartOfWeekForMoment(locale = LOCALE_EN, startOfWeek) {
  const lang = langs[locale];
  await import(`moment/locale/${lang}` /* webpackChunkName:"locale" */);
  moment.locale(lang);
  if (!startOfWeek) return;
  moment.updateLocale(lang, {
    week: {
      dow: startOfWeek === 'sunday' ? 0 : 1,
    },
  });
}

/***
 * 延迟指定时间
 * @param {Number} delayTime 延迟毫秒数
 */
export async function sleep(delayTime) {
  return new Promise((resolve) => {
    setTimeout(resolve, delayTime);
  });
}

/**
 *
 * @param {*} momentTime
 * @returns 2025-01-31T00:00:00Z , 2025-01-31T23:59:59Z
 */
export function parseToBeginEndUTC(time) {
  const year = time.year();
  const month = time.month();
  const day = time.date();

  return {
    begin: moment.utc({ year, month, day }).startOf('day').format(),
    end: moment.utc({ year, month, day }).endOf('day').format(),
  };
}

/**
 * 时间格式化(HH:mm)
 * @param {string} time 24小时的时间字符串,时钟+分钟
 */
export function formatTimeIn12HoursFormat(time) {
  if (!time) return time;

  let [hour, minute] = time.split(':');
  let h = +hour;
  const isPM = h > 12;

  if (isPM) {
    h -= 12;
  } else if (h === 0) {
    h = 12;
  }

  return `${String(h).padStart(2, '0')}:${minute} ${isPM ? 'PM' : 'AM'}`;
}
