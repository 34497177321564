import { FONT_FAMILY } from 'src/consts/fonts';

const { PROXIMA_NOVA } = FONT_FAMILY;

/**
 * 根据登录用户生成水印图片
 * @param {string} username 用户名
 * @param {string} timestamp 时间戳, 例如2/7/2025 10:51:47 AM
 * @param {string} roleName 角色名，AM/Ops/Admin
 * @return svg data uri
 */
export function generateWaterMarkerImage(username, timestamp, roleName = 'Admin') {
  const svgContent = `
    <svg width="350" height="200" xmlns='http://www.w3.org/2000/svg'>
      <defs>
        <pattern id="watermarker" patternUnits="userSpaceOnUse" width="350" height="200"  x="0" y="0">
          <g transform="translate(30, 80) rotate(-22)">
            <text x="0" y="0" font-family="${PROXIMA_NOVA}" font-size="16" fill="rgba(140, 140, 140, 0.4)">
              ${roleName} ${username}
            </text>
            <text x="0" y="19" font-family="${PROXIMA_NOVA}" font-size="16" fill="rgba(140, 140, 140, 0.4)">
              ${timestamp}
            </text>
          </g>
        </pattern>
      </defs>
      <rect width="350" height="200" fill="url(#watermarker)" x="0" y="0" />
    </svg>
  `
    .replace(/\s+</g, '<')
    .replace(/>\s+/g, '>');
  const encoder = new TextEncoder();
  const encodedString = window.btoa(String.fromCharCode(...encoder.encode(svgContent)));
  return `data:image/svg+xml;base64,${encodedString}`;
}

export function generatePDFWaterMarkerImage(username, timestamp, roleName = 'Admin') {
  const svgContent = `
    <svg width="100%" height="100%" xmlns='http://www.w3.org/2000/svg'>
      <defs>
        <pattern id="watermarker" patternUnits="userSpaceOnUse" width="350" height="250"  x="0" y="0">
          <g transform="translate(20, 100) rotate(-22)">
            <text x="0" y="0" font-family="${PROXIMA_NOVA}" font-size="16" fill="rgba(140, 140, 140, 0.4)">
              ${roleName} ${username}
            </text>
            <text x="0" y="19" font-family="${PROXIMA_NOVA}" font-size="16" fill="rgba(140, 140, 140, 0.4)">
              ${timestamp}
            </text>
          </g>
          <g transform="translate(120, 210) rotate(-22)">
            <text x="0" y="0" font-family="${PROXIMA_NOVA}" font-size="16" fill="rgba(140, 140, 140, 0.4)">
            ${roleName} ${username}
            </text>
            <text x="0" y="19" font-family="${PROXIMA_NOVA}" font-size="16" fill="rgba(140, 140, 140, 0.4)">
              ${timestamp}
            </text>
          </g>
        </pattern>
      </defs>
      <rect  width="100%" height="100%" fill="url(#watermarker)" x="0" y="0" />
    </svg>
  `
    .replace(/\s+</g, '<')
    .replace(/>\s+/g, '>');
  const encoder = new TextEncoder();
  const encodedString = window.btoa(String.fromCharCode(...encoder.encode(svgContent)));
  return `data:image/svg+xml;base64,${encodedString}`;
}
