import router from 'next/router';
import { setSessionItem, KEYS, setCookie, getSessionItem } from '../store/storage';
import { queryClientAppConfig } from '../services/appconfig';
import { actions as userActions, clearUserSession, handleSessionData } from './user';
import {
  getRestaurantGroup,
  getRestaurantList,
  switchCurrentRestaurant,
  getRestaurantGroupInfo,
  getRestaurantGroupDealContract,
  getRestaurantGroupGiftCardContract,
} from '../services/restaurantGroup';
import { PageDashboard } from '../consts/routes';
import { getUserInfo } from '../utils/utils';
import { getOwnedRestaruants } from '../services/employee';
import { isNotEmptyArray } from '../utils/array';
import { actions as rgUserActions } from './rgUser';
import { EXIST_FLAG } from 'src/consts/rgflags';
import { updateStartOfWeekForMoment } from 'src/utils/time';

const BASE = 'APP';
const DEFAULT_VERSION_NAME = 'ios';

export const actions = {
  switchLocale: `${BASE}_SWITCH_LOCALE`,
  appConfigLoaded: `${BASE}_APP_CONFIG_LOADED`,
  setIsInApp: `${BASE}_SET_IS_IN_APP`,
  setReady: `${BASE}_READY`,
  changeLayoutCollapsed: `${BASE}_CHANGE_LAYOUT_COLLAPSED`,
  changeFeatureConfig: `${BASE}_CHANGE_FEATURE_CONFIG`,
  switchingRestaurant: `${BASE}_SWITCHING_RESTAURANT`,
  switchedRestaurant: `${BASE}_SWITCHED_RESTAURANT`,
  loadingRestaurantList: `${BASE}_LOADING_RESTAURANT_LIST`,
  loadRestaurantListSuccess: `${BASE}_LOAD_RESTAURANT_LIST_SUCCESS`,
  loadRestaurantListFailed: `${BASE}_LOADING_RESTAURANT_LIST_FAILED`,
  setRestaurantList: `${BASE}_SET_RESTAURANT_LIST`,
  setIsGroup: `${BASE}_SET_IS_GROUP`,
  clearAppSession: `${BASE}_CLEAR_APP_SESSION`,
};

export const switchLocale = (locale) => async (dispatch, getState) => {
  setSessionItem({ [KEYS.locale]: locale });
  setCookie({ [KEYS.locale]: locale });
  const { setting } = getState();
  await updateStartOfWeekForMoment(locale, setting?.settings?.start_of_week);

  const messages = await import(`../locale/${locale}.js` /* webpackChunkName:"locale" */);
  dispatch({
    type: actions.switchLocale,
    payload: {
      locale,
      messages: messages.default,
    },
  });
};

export const switchLocaleForCustomerOrderReviewPage = (locale) => async (dispatch, getState) => {
  setSessionItem({ [KEYS.locale]: locale });
  setCookie({ [KEYS.locale]: locale });

  const messages = await import(`../locale/customerOrderReviews_${locale}.js` /* webpackChunkName:"locale" */);
  dispatch({
    type: actions.switchLocale,
    payload: {
      locale,
      messages: messages.default,
    },
  });
};

export const fetchAppConfig = (callback) => async (dispatch) => {
  let response;

  try {
    response = await queryClientAppConfig();
  } catch (e) {
    response = { success: false };
  }

  if (typeof callback === 'function') callback(response.success);
  if (!response.success) return;

  let baseAppConfig = {};
  const appConfigs = (response.data || []).map((item) => {
    if (item.name === DEFAULT_VERSION_NAME) baseAppConfig = item;

    let restaurant_ids;
    try {
      restaurant_ids = JSON.parse(item.keys.update_prompt_restaurants);
    } catch (e) {
      restaurant_ids = [];
    }
    return {
      ...item,
      restaurant_ids,
    };
  });

  dispatch({
    type: actions.appConfigLoaded,
    payload: {
      baseAppConfig,
      allAppConfigs: appConfigs,
    },
  });
};

export const setIsInApp = (isInApp) => async (dispatch) => {
  dispatch({
    type: actions.setIsInApp,
    payload: isInApp,
  });
};

export const setIsGroup = (isGroup) => async (dispatch) => {
  dispatch({
    type: actions.setIsGroup,
    payload: isGroup,
  });
};

export const setAppReady = (ready) => async (dispatch) => {
  dispatch({
    type: actions.setReady,
    payload: ready,
  });
};

export const clearAppSessions = () => async (dispatch) => {
  dispatch({
    type: actions.clearAppSession,
  });
};

export const fetchRestaurantList = () => async (dispatch, getState) => {
  const store = getState();
  const { isLoadRestaurantListSuccess, isLoadingRestaurantList } = store.app;
  if (isLoadRestaurantListSuccess || isLoadingRestaurantList) return;
  const { currentUser } = store.user;
  let response;

  dispatch({
    type: actions.loadingRestaurantList,
  });

  try {
    response = await getRestaurantGroup();
  } catch (e) {
    response = { success: false };
  }

  if (!response.success) {
    dispatch({
      type: actions.loadRestaurantListFailed,
    });
    return;
  }

  const {
    data: { data },
  } = response;

  if (!data || !data.length) {
    dispatch({
      type: actions.loadRestaurantListSuccess,
      payload: [],
    });
    return;
  }

  let _response;

  try {
    _response = await getRestaurantList(JSON.stringify(data), currentUser?.sub);
  } catch (e) {
    _response = { success: false };
  }

  // remove group nodes that are not in the tagrget group list
  let _restaurantList = [];
  if (isNotEmptyArray(_response.data) && isNotEmptyArray(data)) {
    _restaurantList = _response.data.filter((item) => {
      return data.some((_item) => `${_item}` == `${item.id}`);
    });
  }

  dispatch({
    type: actions.loadRestaurantListSuccess,
    payload: _restaurantList,
  });

  let res;
  const userInfo = getUserInfo();

  try {
    res = await getOwnedRestaruants(userInfo.sub);
  } catch (e) {
    res = { success: false };
  }

  if (!res.success) {
    dispatch({ type: userActions.loadOwnedRestaurantsFailed });
    return;
  }

  const { restaurant_ids } = res.data;
  dispatch({
    type: userActions.loadOwnedRestaurantsSuccess,
    payload: restaurant_ids,
  });

  dispatch({
    type: rgUserActions.owningRestaurants,
    payload: Array.isArray(restaurant_ids) && restaurant_ids.length > 0 ? EXIST_FLAG.exist : EXIST_FLAG.notExist,
  });
};

export const switchRestaurant = (newRestaurantId) => async (dispatch) => {
  dispatch({ type: actions.switchingRestaurant });

  let response;

  try {
    response = await switchCurrentRestaurant(newRestaurantId);
  } catch (e) {
    response = { success: false };
  }

  if (!response.success) return;

  const payload = response.data || {};
  dispatch({ type: actions.switchedRestaurant });
  dispatch(clearUserSession());
  dispatch(handleSessionData(payload));
  router.replace(PageDashboard);
};

export const fetchRestaurantGrouInfo = () => async (dispatch, getState) => {
  const store = getState();
  const { isLoadRestaurantGroupInfoDone, isLoadingRestGroupInfo } = store.user;
  if (isLoadRestaurantGroupInfoDone || isLoadingRestGroupInfo) return;
  const restaurant_id = getSessionItem(KEYS.restaurantId);

  dispatch({
    type: userActions.loadRestaurantGroupInfo,
  });

  // most restaurants are not in a group
  let groupResponse, contractResponse, giftCardContractResponse;
  try {
    groupResponse = await getRestaurantGroupInfo(restaurant_id);
  } catch (error) {
    groupResponse = { success: false };
  }

  if (!groupResponse.success) {
    dispatch({
      type: userActions.loadRestaurantGroupInforFailed,
    });
    return;
  }

  [contractResponse, giftCardContractResponse] = await Promise.all([
    new Promise(async (resolve) => {
      try {
        let resultData = await getRestaurantGroupDealContract(restaurant_id);
        resolve({
          data: resultData.data,
          success: true,
        });
      } catch (e) {
        resolve({
          success: false,
        });
      }
    }),
    new Promise(async (resolve) => {
      try {
        let resultData = await getRestaurantGroupGiftCardContract(restaurant_id);
        resolve({
          data: resultData.data,
          success: true,
        });
      } catch (e) {
        resolve({
          success: false,
        });
      }
    }),
  ]);

  if (!contractResponse.success && !giftCardContractResponse.success) {
    dispatch({
      type: userActions.loadRestaurantGroupInforFailed,
    });
    return;
  }

  const restaurantGroupInfoResultData = groupResponse.data;
  const restaurantGroupDealContractResultData = contractResponse.data;
  const restaurantGroupGiftCardDealContractResultData = giftCardContractResponse?.data || [];

  if (!restaurantGroupInfoResultData?.id) {
    dispatch({
      type: userActions.loadRestaurantGroupInforFailed,
    });
  } else {
    dispatch({
      type: userActions.loadRestaurantGroupInforSucess,
      payload: {
        restaurantGroupInfo: restaurantGroupInfoResultData || null,
        restaurantGroupDealContract:
          Array.isArray(restaurantGroupDealContractResultData) && restaurantGroupDealContractResultData.length !== 0
            ? restaurantGroupDealContractResultData[0]
            : null,
        restaurantGroupGiftCardDealContract:
          Array.isArray(restaurantGroupGiftCardDealContractResultData) &&
          restaurantGroupGiftCardDealContractResultData.length !== 0
            ? restaurantGroupGiftCardDealContractResultData[0]
            : null,
      },
    });
  }
};
