import axios from 'axios';
import { getSessionItem, KEYS } from '../store/storage';
import { handleAuthorData, logout } from '../actions/user';
import { AUTH_API_PREFIX } from '../consts';
import { POS_AUTH_CLIENT_ID } from '../consts/third_party';

const refreshTokenInterval = 5 * 1000;
let retryCount = 3;

class RefreshTokenClient {
  constructor() {
    this.refreshTokenPromise = null;
    this.client = axios.create();
  }

  async _refreshTokenHandle() {
    const refreshToken = getSessionItem(KEYS.refreshToken);
    let response;

    try {
      response = await this.client.request({
        url: `${AUTH_API_PREFIX}/oauth/token`,
        method: 'POST',
        data: { client_id: POS_AUTH_CLIENT_ID, grant_type: 'refresh_token', refresh_token: refreshToken },
        noNeedLogin: true,
      });
      return Promise.resolve(response.data);
    } catch (e) {
      return Promise.reject('Refresh token failed');
    }
  }

  async _getRefreshToken() {
    try {
      return this._refreshTokenHandle();
    } catch (e) {
      retryCount -= 1;
      if (retryCount > 1) {
        return this._getRefreshToken();
      }
    }
  }

  /**
   * 此方法缓存刷新请求，以防止同一时间大量接口发生401，每个请求都会触发一次refresh token,
   * 缓存后在5秒钟内只会发送一次refresh token请求。
   * 同一时间大量的refresh token请求可能导致auth判定为恶意请求而导致请求被block
   */
  _getRefreshUserTokenPromise() {
    if (!this.refreshTokenPromise) {
      retryCount = 3;
      this.refreshTokenPromise = this._getRefreshToken();
    }

    return this.refreshTokenPromise;
  }

  async refreshTokenAfter401(store) {
    const refreshToken = getSessionItem(KEYS.refreshToken);
    if (!refreshToken) {
      return Promise.reject(new Error('Refresh token not existed'));
    }

    try {
      const userInfo = await this._getRefreshUserTokenPromise();
      handleAuthorData(userInfo);
      setTimeout(() => {
        this.refreshTokenPromise = null;
      }, refreshTokenInterval);
      return Promise.resolve(userInfo);
    } catch (e) {
      store.dispatch(logout());
      return Promise.reject(new Error('Refresh token failed'));
    }
  }

  /**
   * 请求前预先检查token，如果token不存在则直接登出，不发送请求
   * 如果token已过有效期，则先刷新token后使用刷新后的token发送请求
   * 这样可以减少可预见的401接口请求数量
   * @param {ReduxStore} store
   * @returns
   */
  async beforeRequest(store) {
    const token = getSessionItem(KEYS.token);
    if (!token) {
      store.dispatch(logout());
      return Promise.reject(new Error('Token expired'));
    }

    const expireTime = getSessionItem(KEYS.expireTime, 0);
    if (expireTime > new Date().getTime()) {
      return Promise.resolve();
    }

    return await this.refreshTokenAfter401(store);
  }
}

export const refreshTokenClient = new RefreshTokenClient();
