import JwtDecode from 'jwt-decode';
import { getUserInfo } from './utils';
import { getSessionItem, USER_KEYS } from '../store/storage';

const KEY_SCOPE = 'chowbus.com';
const IDENTITY_KEY = `${KEY_SCOPE}/identity`;
const RESTAURANT_ID_KEY = `${KEY_SCOPE}/restaurant_id`;
const PERMISSION_KEY = `${KEY_SCOPE}/permissions`;
const VIRTUAL_ROLES = {
  virtual_am_gm: 'AM',
  virtual_ops: 'Ops',
  virtual_super_admin_roles: 'Admin',
};

export function checkIsSelf(employeeId) {
  return getUserInfo().sub === employeeId;
}

export function getEmployeeName(employee, fullName = false) {
  if (!employee) return null;
  const { first_name, last_name } = employee;
  const firstName = first_name || '';
  const lastName = last_name || '';
  if (fullName) return `${firstName} ${lastName}`;
  return `${firstName} ${lastName.slice(0, 1)}`;
}

export function extractRestaurantId(data) {
  const identity = data[IDENTITY_KEY];
  const restaurantId = data[RESTAURANT_ID_KEY];
  return { identity, restaurantId };
}

/**
 * 提取用户permissions
 * @param {User} data
 */
export function extraVirtualRoleName(data) {
  const rawPermissions = data[PERMISSION_KEY];
  if (!rawPermissions) return null;

  const roleKey = Object.keys(VIRTUAL_ROLES).find((key) => rawPermissions.includes(key));
  return !roleKey ? null : VIRTUAL_ROLES[roleKey];
}

export function getCurrentUserInfoFromToken(token) {
  const decoded = JwtDecode(token);
  const { expires_in = 1800, email = '', exp } = decoded;
  const expireTime = (exp ? exp * 1000 : new Date().getTime()) + (expires_in - 30) * 1000;
  const { restaurantId, identity } = extractRestaurantId(decoded);
  return {
    currentUser: decoded,
    currentRestaurantId: restaurantId,
    email,
    expireTime,
    identity,
  };
}

export function getCurrentUserInfoFromSession() {
  let currentUserInfo;

  try {
    currentUserInfo = JSON.parse(getSessionItem(USER_KEYS.userInfo) || '{}');
  } catch (e) {
    currentUserInfo = {};
  }

  return currentUserInfo;
}

/***
 * checkNavMenuVisible 判断某个导航菜单是否可见
 * @param params.menu {object} 导航菜单
 * @param params.restaurantInfo {object} 餐厅信息
 */
export function checkNavMenuVisible({ menu, restaurantInfo }) {
  const { distributionMode, visibleChecker } = menu;
  let visible = true;

  if (typeof visibleChecker === 'function') {
    visible = visibleChecker({ restaurantInfo });
  } else if (distributionMode) {
    const { restaurant_contracts } = restaurantInfo;
    const modes = (restaurant_contracts || []).map((_) => _.distribution_mode);
    visible = modes.includes(distributionMode);
  }

  return visible;
}
